import { AppBar, IconButton, Toolbar, Typography, Box } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { clearSessionCookies } from "../Utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const TopBar = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setLoggedOut } = useAuth();

  const handleLogoutButton = async () => {
    try {
      const response = await fetch("/logout", {
        method: "POST", // You can adjust the HTTP method as needed
      });

      if (response.status === 200) {
        const responseData = await response.json();
        clearSessionCookies();
        setIsAuthenticated(false);
        setLoggedOut(true);
        navigate("/");
      } else {
        console.error("Logout failed. Status code: " + response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <AppBar sx={{ width: "100%" }} enableColorOnDark>
      <Toolbar
        style={{
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="secondary"
          sx={{ fontSize: "1.25rem" }}
        >
          Financial Web App
        </Typography>

        <IconButton
          color="secondary"
          style={{ marginRight: "1rem", cursor: "pointer" }}
          onClick={handleLogoutButton}
        >
          <PowerSettingsNewIcon style={{ fontSize: "100%" }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
