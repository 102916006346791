import SearchBar from "./SearchBar";
import Grid from "@mui/system/Unstable_Grid/Grid";
import StockDataSelectionTab from "./StockDataSelectionTab";
import StockStatistics from "./StockStatistics/StockStatistics";
import StockNews from "./StockNews/StockNews";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import StockFundamentals from "./Fundamentals/StockFundamentals";

const getGridBreakpoints = { xs: 10, md: 10, lg: 10 };
const StockDataApp = () => {
  const [selection, setselection] = useState("statistics");
  const [ticker, setticker] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [tickerName, settickerName] = useState("");

  const handleSearchBarChange = (event, value) => {
    var tickerValue = value ? value.split(" - ")[0] : "";
    var tickerName = value ? value.split(" - ")[1] : "";
    setticker(tickerValue);
    settickerName(tickerName);
    setIsloading(true);
  };
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
        >
          <SearchBar handleSearchBarChange={handleSearchBarChange} />
        </Grid>
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
        >
          <StockDataSelectionTab
            selection={selection}
            setselection={setselection}
          />
        </Grid>
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
        >
          {Boolean(ticker) ? (
            <>
              {selection === "news" && (
                <StockNews
                  ticker={ticker}
                  setIsloading={setIsloading}
                  isLoading={isLoading}
                />
              )}
              {selection === "statistics" && (
                <StockStatistics ticker={{ name: tickerName, value: ticker }} />
              )}
              {selection === "fundamentals" && (
                <StockFundamentals
                  ticker={{ name: tickerName, value: ticker }}
                />
              )}
            </>
          ) : (
            <Box
              mt={5}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                Please select a ticker!
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default StockDataApp;
