import { Box } from "@mui/system";
import FundamentalsTable from "./FundamentalsTable";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

const CashFlow = ({ ticker, periodSelection }) => {
  const [cashFlow, setcashFlow] = useState({});

  const fetchTickerCashFlow = (ticker, period) => {
    try {
      const data = fetch(`/api/cashflow/${ticker}/${period}`);

      data
        .then((resp) => {
          const responseData = resp.ok ? resp.json() : {};
          return responseData;
        })
        .then((data) => setcashFlow(data));
    } catch {
      console.log("Error");
      setcashFlow({});
    }
  };

  useEffect(() => {
    fetchTickerCashFlow(ticker.value, periodSelection);
  }, [ticker.value, periodSelection]);

  return (
    <>
      <Box mt={3}>
        <Typography fontSize="20px" fontWeight="Bold">
          {ticker.value} ({ticker.name})
        </Typography>
      </Box>
      <FundamentalsTable header="Income Statement" data={cashFlow} />
    </>
  );
};

export default CashFlow;
