import { TableRow, TableCell, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";

const FundamentalsRows = ({ selected, header, data, paddingLeft }) => {
  const stepPadding = paddingLeft + 5;
  const [open, setOpen] = useState(true);
  const [totals, settotals] = useState([]);
  const [dataWOTotals, setdataWOTotals] = useState({});
  const [selectedRows, setSelectedRows] = useState(new Set());

  const handleRowSelectedClick = (event, key) => {
    setSelectedRows((prevSelectedRows) => {
      // Create a new Set based on the previous selection
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(key)) {
        // If the row is already selected, remove it
        newSelectedRows.delete(key);
      } else {
        // If the row is not selected, add it
        newSelectedRows.add(key);
      }
      return newSelectedRows;
    });
  };

  useEffect(() => {
    settotals(data["totals"]);
    const newData = { ...data };
    delete newData["totals"];
    setdataWOTotals(newData);
  }, [data]);
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="td"
          scope="row"
          sx={{
            fontWeight: "bold",
            paddingLeft: paddingLeft,
            whiteSpace: "nowrap",
            paddingBottom: "8px",
            paddingTop: "8px",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {header.toUpperCase()}
        </TableCell>
        {Boolean(totals) &&
          totals.map((val) => (
            <TableCell
              component="td"
              scope="row"
              sx={{
                paddingLeft: stepPadding,
                whiteSpace: "nowrap",
                fontWeight: "bold",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
              align="right"
            >
              {val ? val : "-"}
            </TableCell>
          ))}
      </TableRow>
      {open &&
        Object.entries(dataWOTotals).map(([key, value]) =>
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value) ? (
            <FundamentalsRows
              header={key}
              data={value}
              paddingLeft={stepPadding}
            />
          ) : (
            <TableRow
              onClick={(event) => handleRowSelectedClick(event, key)}
              hover
              selected={selectedRows.has(key)}
              key={key}
              sx={{
                rowHeight: "20",
              }}
            >
              <TableCell
                component="td"
                scope="row"
                sx={{
                  paddingLeft: paddingLeft + 5,
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
              >
                {key}
              </TableCell>
              {Array.isArray(value) ? (
                value.map((val) => (
                  <TableCell
                    component="td"
                    scope="row"
                    sx={{
                      paddingLeft: stepPadding,
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      paddingBottom: "8px",
                      paddingTop: "8px",
                    }}
                    align="right"
                  >
                    {val ? val : "-"}
                  </TableCell>
                ))
              ) : (
                <></>
              )}
            </TableRow>
          )
        )}
    </>
  );
};

export default FundamentalsRows;
