import Grid from "@mui/system/Unstable_Grid/Grid";
import React from "react";
import DocumentSummarizer from "./DocumentSummarizer";

const getGridBreakpoints = { xs: 10, md: 10, lg: 10 };

const FinancialInsightsApp = () => {
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
        >
          <DocumentSummarizer />
        </Grid>
      </Grid>
    </>
  );
};

export default FinancialInsightsApp;
