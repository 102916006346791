export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function getGridItemSx() {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    width: "100%  ",
  };
}

export async function clearSessionCookies() {
  var Cookies = document.cookie.split(";");
  // set 1 Jan, 1970 expiry for every cookies
  for (var i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
}
