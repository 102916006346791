import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../Utils";
import CSRFToken from "./CSRFToken";
import Toast from "./Toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

const defaultTheme = createTheme();
const csrftoken = getCookie("csrftoken");

export default function SignUp() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [usernameErrorText, setusernameErrorText] = useState("");
  const [emailErrorText, setemailErrorText] = useState("");
  const [formData, setformData] = useState({
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeUsername = (event) => {
    setformData((prev) => {
      return { ...prev, username: event.target.value };
    });
  };

  const handleChangeEmail = (event) => {
    setformData((prev) => {
      return { ...prev, email: event.target.value };
    });
  };

  const handleChangePassword = (event) => {
    setformData((prev) => {
      return { ...prev, password: event.target.value };
    });
  };

  const handleChangeFirstName = (event) => {
    setformData((prev) => {
      return { ...prev, firstname: event.target.value };
    });
  };

  const handleChangeLastName = (event) => {
    setformData((prev) => {
      return { ...prev, lastname: event.target.value };
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
      if (response.status === 201) {
        setemailErrorText("");
        setusernameErrorText("");
        // Registration successful, you can redirect the user to sign in page.
        setToastMessage(responseData.message); // Assuming responseData contains a 'message' property
        setOpenToast(true);
        setTimeout(() => navigate("/"), 3000);
      } else {
        // Authentication failed, you can handle the error (e.g., show an error message)
        setemailErrorText(
          responseData.error_reason === "username" ? "" : "Email already taken!"
        );
        setusernameErrorText(
          responseData.error_reason === "email" ? "" : "Username already taken!"
        );
        setToastMessage(responseData.message); // Assuming responseData contains a 'message' property
        setOpenToast(true);
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleChangeFirstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={handleChangeLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="Username"
                  error={Boolean(usernameErrorText)}
                  helperText={usernameErrorText}
                  onChange={handleChangeUsername}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={Boolean(emailErrorText)}
                  helperText={emailErrorText}
                  onChange={handleChangeEmail}
                />
              </Grid>
              <CSRFToken />
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  onChange={handleChangePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography variant="caption" color="primary">
                  <Link to="/">Already have an account? Sign in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Toast
        open={openToast}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
}
