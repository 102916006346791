import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import CSRFToken from "./CSRFToken";
import { getCookie } from "../Utils";
import Toast from "./Toast";

const defaultTheme = createTheme();
const csrftoken = getCookie("csrftoken");

export default function PasswordConfirm() {
  const navigate = useNavigate();
  const { uidb64 } = useParams();
  const { token } = useParams();
  const [errorText, setErrorText] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [formData, setformData] = useState({
    uid: uidb64,
    token: token,
    new_password2: "",
    new_password1: "",
  });

  const handleChangePassword1 = (event) => {
    setformData((prev) => {
      return { ...formData, new_password1: event.target.value };
    });
  };

  const handleChangePassword2 = (event) => {
    setformData((prev) => {
      return { ...formData, new_password2: event.target.value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const doPasswordsMatch =
      formData?.new_password1 === formData?.new_password2;

    setErrorText(doPasswordsMatch ? "" : "Passwords do not match!");

    if (doPasswordsMatch) {
      try {
        const response = await fetch(`/reset/${uidb64}/${token}`, {
          method: "POST",
          headers: {
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify(formData),
        });
        const responseData = await response.json();
        if (response.status === 200) {
          setToastMessage(responseData.message);
          setOpenToast(true);
          setTimeout(() => navigate("/"), 1000);
        } else {
          console.error("Password setting failed");
          setToastMessage(responseData.message); // Assuming responseData contains a 'message' property
          setOpenToast(true);
        }
      } catch (error) {
        // Handle network errors
        console.error("Network error:", error);
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Set New Password
          </Typography>
          <CSRFToken />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="password"
              type="password"
              autoFocus
              onChange={handleChangePassword1}
              error={Boolean(errorText)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="cpassword"
              label="Confirm Password"
              name="cpassword"
              autoComplete="cpassword"
              type="password"
              autoFocus
              onChange={handleChangePassword2}
              error={Boolean(errorText)}
              helperText={errorText}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Set Password
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography variant="caption" color="primary">
                  <Link to="/">Sign in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Toast
        open={openToast}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
}
