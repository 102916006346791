import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
} from "@mui/material";

import FundamentalsRows from "./FundamentalsRows";

const FundamentalsTable = ({ header, data }) => {
  return (
    <>
      {Boolean(Object.entries(data).length) && (
        <Box>
          <TableContainer
            margi
            sx={{
              overflowX: "scroll",
              overflowY: "scroll",
              width: "70vw",
              height: "55vh",
            }}
          >
            <Box mt={1} sx={{ boxShadow: 1 }}>
              <Table sx={{ maxWidth: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      sx={{
                        fontWeight: "bold",
                        width: "25rem",
                      }}
                    >
                      Description
                    </TableCell>
                    {data?.fiscal_date.map((val) => (
                      <TableCell
                        component="th"
                        sx={{
                          fontWeight: "bold",
                          width: "3rem",
                        }}
                        align="right"
                      >
                        {val}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data.data).map(([key, value]) =>
                    typeof value === "object" &&
                    value !== null &&
                    !Array.isArray(value) ? (
                      <FundamentalsRows
                        header={key}
                        data={value}
                        paddingLeft={0}
                      />
                    ) : (
                      <TableRow hover key={key}>
                        <TableCell
                          component="td"
                          style={{
                            whiteSpace: "nowrap",
                            paddingBottom: "8px",
                            paddingTop: "8px",
                            width: "auto",
                          }}
                        >
                          {key}
                        </TableCell>
                        {Boolean(value) ? (
                          value.map((val) => (
                            <TableCell
                              component="td"
                              scope="row"
                              align="right"
                              sx={{
                                whiteSpace: "nowrap",
                                paddingBottom: "8px",
                                paddingTop: "8px",
                                fontWeight: "bold",
                                width: "3rem",
                              }}
                            >
                              {val ? val : "-"}
                            </TableCell>
                          ))
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default FundamentalsTable;
