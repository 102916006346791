import { TextField, Box } from "@mui/material";

const WordCount = ({ wordCount, handleWordCountChange }) => {
  return (
    <Box mt={2} sx={{ width: "10rem" }}>
      <TextField
        fullWidth
        label="Target Word Count"
        variant="outlined"
        type="number"
        value={wordCount}
        onChange={handleWordCountChange}
        inputProps={{ step: 50, min: 0, max: 1000 }}
        InputLabelProps={{
          style: {
            fontSize: ".9rem",
          },
        }}
      />
    </Box>
  );
};

export default WordCount;
