import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Search as SearchIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const SearchBar = ({ handleSearchBarChange }) => {
  const [stocksList, setstocksList] = useState([]);

  useEffect(() => {
    fetchStocksList();
  }, []);

  const fetchStocksList = () => {
    try {
      const dataResponse = fetch("/api/tickers");
      dataResponse
        .then((data) => data.json())
        .then((data) => setstocksList(data));
    } catch {
      console.log("Error!");
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography>
        This is our stock lookup tool. It gives you quick, full breakdowns of a
        company's financials.
      </Typography>
      <Autocomplete
        options={stocksList.map((val) => `${val.ticker} - ${val.name}`)}
        onChange={handleSearchBarChange}
        fullWidth
        autoComplete
        limitTags={10}
        value={stocksList[0]}
        sx={{ margin: 2 }}
        ListboxProps={{
          style: {
            maxHeight: "25vh", // Set the dropdown height to 10% of viewport height
            overflowY: "auto", // Enable scrolling in the dropdown if it exceeds maxHeight
            zIndex: 9999, // Ensure the dropdown appears on top of other elements
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchIcon color="disabled" />
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBar;
