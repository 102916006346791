import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";

const SideBarItem = ({ tab, componentRoute, componentIcon, showText }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const onCurrentComponent = useMemo(() => {
    return componentRoute === window.location.pathname;
  }, [window.location.pathname]);

  const listItemIconSx = {
    justifyContent: "center",
    color: onCurrentComponent
      ? theme.palette.text.primary
      : theme.palette.text.third,
  };

  const listItemButtonSx = {
    justifyContent: "center",
  };

  const listItemTextSx = {
    fontSize: "0.8rem",
    color: onCurrentComponent
      ? theme.palette.text.primary
      : theme.palette.text.third,
  };

  return (
    <ListItem key={tab} disablePadding sx={{ justifyItems: "center" }}>
      <ListItemButton
        key={tab}
        selected={onCurrentComponent}
        onClick={() => navigate(componentRoute)}
        sx={listItemButtonSx}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            width: "100%",
            margin: "0.3rem",
          }}
        >
          <ListItemIcon key={tab} sx={listItemIconSx}>
            {componentIcon}
          </ListItemIcon>

          {showText && (
            <ListItemText primary={tab} disableTypography sx={listItemTextSx} />
          )}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default SideBarItem;
