import { Box, Tab, Tabs } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const FundamentalsSelectionTab = ({
  selection,
  handleFundamentalsSelectionChange,
  periodSelection,
  handleFundamentalsPeriodChange,
}) => {
  return (
    <>
      <Box sx={{ width: "900", justifyContent: "center" }}>
        <Box sx={{ width: "100%", justifyContent: "center" }}>
          <Tabs
            onChange={handleFundamentalsSelectionChange}
            centered
            sx={{
              width: "100%",
              justifyContent: "center",
            }}
            indicatorColor="secondary"
            textColor="secondary"
            value={selection}
          >
            <Tab
              value="balancesheet"
              label="Balance Sheet"
              sx={{ textTransform: "none" }}
            />
            <Tab
              value="incomestatement"
              label="Income Statement"
              sx={{ textTransform: "none" }}
            />
            <Tab
              value="cashflow"
              label="Cash Flow Statement"
              sx={{ textTransform: "none" }}
            />
          </Tabs>
        </Box>
        <Box mt={2}>
          <FormControl>
            <InputLabel id="select-required-label">Period</InputLabel>
            <Select
              labelId="select-required-label"
              defaultValue="quarterly"
              label="Period"
              value={periodSelection}
              onChange={handleFundamentalsPeriodChange}
              sx={{
                width: 200,
                height: 45,
              }}
            >
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="annual">Annual</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default FundamentalsSelectionTab;
