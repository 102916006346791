import { ArrowDownward, Search } from "@mui/icons-material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";

import {
  Grid,
  Box,
  Button,
  Typography,
  IconButton,
  LinearProgress,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { DataContext } from "../Context/DataContext";
import numeral from "numeral";

const getGridBreakpoints = { xs: 10, md: 10, lg: 10 };

const dateFormat = "Do MMM YY";

createTheme(
  "solarized",
  {
    text: {
      primary: "#ffff00",
      secondary: "#ffff00",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const customStyles = {
  cells: {
    style: {
      padding: 1,
      fontSize: "0.9rem",
    },
  },
  headCells: {
    style: { padding: 1, fontSize: "0.9rem", fontWeight: "bold" },
  },
  rows: {
    style: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  head: {
    style: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
};

export const EarningsCalendarApp = () => {
  const [pickedDate, setPickedDate] = useState(null);
  const [noonFilter, setNoonFilter] = useState([]);

  const {
    data,
    date,
    dates,
    setDate,
    tableData,
    setTableData,
    fetchEarningsData,
    loading,
  } = useContext(DataContext);

  const handleDateChange = (date) => {
    setDate(date);
    setTableData(data.filter((item) => item.date === date));
  };

  const handlePickedDateChange = (date) => {
    const dateObj = date ? date : new Date();
    setPickedDate(dateObj.format("yyyy-MM-DD"));
  };

  const handleSearchClick = () => {
    fetchEarningsData(pickedDate);
  };

  const handleNoonFilterChange = (event, newVal) => {
    setNoonFilter(newVal);
  };

  const filteredTableData = useMemo(() => {
    if (noonFilter.length === 1) {
      const condition = noonFilter[0];
      return condition === "before"
        ? tableData.filter((item) => item.isBeforeNoonEST)
        : tableData.filter((item) => !item.isBeforeNoonEST);
    } else {
      return tableData;
    }
  }, [tableData, noonFilter]);

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <Grid
        item
        xs={getGridBreakpoints.xs}
        md={getGridBreakpoints.md}
        lg={getGridBreakpoints.lg}
        sx={{ width: "100%" }}
      >
        <Box
          sx={{
            borderTop: "0.1rem solid",
            borderBottom: "0.1rem solid",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {dates &&
            dates.map((item) => {
              const dispVal = moment(item);

              return (
                <Button
                  sx={{ flex: 1, textTransform: "none" }}
                  color={date === item ? "secondary" : "third"}
                  onClick={() => handleDateChange(item)}
                >
                  {dispVal.format(dateFormat)}
                </Button>
              );
            })}
        </Box>
      </Grid>
      <Grid
        item
        xs={getGridBreakpoints.xs}
        md={getGridBreakpoints.md}
        lg={getGridBreakpoints.lg}
        sx={{
          width: "100%",
          alignItems: "flex-end",
          display: "flex",
          justifyContent: "space-between",
          marginTop: 1,
        }}
      >
        <Typography variant="h5">Earnings Calendar</Typography>

        <Box
          sx={{
            display: "flex",
            width: "40%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <ToggleButtonGroup
              value={noonFilter}
              onChange={handleNoonFilterChange}
              sx={{ width: "100%" }}
              size="small"
            >
              <Tooltip title="Before Noon [E.T.]">
                <ToggleButton
                  value="before"
                  aria-label="before"
                  sx={{ width: "40%" }}
                >
                  <WbSunnyIcon
                    color={noonFilter.includes("before") ? "secondary" : ""}
                  />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="After Noon [E.T.]">
                <ToggleButton
                  value="after"
                  aria-label="after"
                  sx={{ width: "40%" }}
                >
                  <NightlightRoundIcon
                    color={noonFilter.includes("after") ? "secondary" : ""}
                  />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ display: "flex", flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Select date"
                slotProps={{
                  textField: { size: "small" },
                  openPickerIcon: { color: "secondary" },
                }}
                format={dateFormat}
                onChange={handlePickedDateChange}
              />
            </LocalizationProvider>
            <IconButton
              sx={{ display: "flex", alignItems: "center" }}
              onClick={handleSearchClick}
            >
              <Search
                sx={{
                  fontSize: "100%",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={getGridBreakpoints.xs}
        md={getGridBreakpoints.md}
        lg={getGridBreakpoints.lg}
        sx={{ height: "80%", width: "100%" }}
      >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "100%",
            width: "100%",
          }}
        >
          {loading ? (
            <LinearProgress sx={{ width: "100%" }} color="secondary" />
          ) : (
            <DataTable
              columns={columns}
              data={filteredTableData}
              sortIcon={<ArrowDownward />}
              customStyles={customStyles}
              responsive={false}
              theme="solarized"
              fixedHeader
              subHeaderComponent={
                <Typography variant="h5">Earnings Calendar</Typography>
              }
              subHeaderAlign="left"
              dense
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    width: "15rem",
    left: true,
  },
  {
    name: "Ticker",
    selector: (row) => row.ticker,
    sortable: true,
    width: "8rem",
    left: true,
  },
  {
    name: "Date",
    selector: (row) => row.date,
    left: true,
    sortable: true,
    width: "8rem",
  },
  {
    name: "Time",
    selector: (row) => row.time,
    left: true,
    sortable: true,
    width: "8rem",
  },
  {
    name: "EPS",
    selector: (row) => row.eps,
    format: (row) => row.eps || "awaited",
    sortable: true,
    right: true,
    width: "8rem",
  },
  {
    name: "EPS Est.",
    selector: (row) => row.eps_est,
    sortable: true,
    right: true,
    width: "8rem",
  },
  {
    name: "EPS Prior",
    selector: (row) => row.eps_prior,
    sortable: true,
    right: true,
    width: "8rem",
  },

  {
    name: "Revenue",
    selector: (row) => row.revenue,
    format: (row) =>
      row.revenue ? numeral(row.revenue).format("0.0a") : "awaited",
    sortable: true,
    right: true,
    width: "8rem",
  },
  {
    name: "Revenue Est.",
    selector: (row) => row.revenue_est,
    format: (row) =>
      row.revenue_est ? numeral(row.revenue_est).format("0.0a") : "-",
    sortable: true,
    right: true,
    width: "8rem",
  },
  {
    name: "Revenue Prior",
    selector: (row) => row.revenue_prior,
    format: (row) =>
      row.revenue_prior ? numeral(row.revenue_prior).format("0.0a") : "-",
    sortable: true,
    right: true,
    width: "8rem",
  },

  {
    name: "Revenue Guidance Est.",
    selector: (row) => row.revenue_guidance_est,
    format: (row) =>
      row.revenue_guidance_est
        ? numeral(row.revenue_guidance_est).format("0.0a")
        : "-",
    width: "12rem",
    sortable: true,
    right: true,
  },
  {
    name: "Revenue Guidance Min",
    selector: (row) => row.revenue_guidance_min,
    format: (row) =>
      row.revenue_guidance_min
        ? numeral(row.revenue_guidance_min).format("0.0a")
        : "-",
    width: "12rem",
    right: true,
    sortable: true,
  },
  {
    name: "Revenue Guidance Max",
    selector: (row) => row.revenue_guidance_max,
    format: (row) =>
      row.revenue_guidance_max
        ? numeral(row.revenue_guidance_max).format("0.0a")
        : "-",
    width: "12rem",
    right: true,
    sortable: true,
  },
  {
    name: "EPS Guidance Est.",
    selector: (row) => row.eps_guidance_est,
    format: (row) => row.eps_guidance_est || "-",
    width: "12rem",
    right: true,
    sortable: true,
  },
  {
    name: "EPS Guidance Min",
    selector: (row) => row.eps_guidance_min,
    format: (row) => row.eps_guidance_min || "-",
    width: "12rem",
    right: true,
    sortable: true,
  },
  {
    name: "EPS Guidance Max",
    selector: (row) => row.eps_guidance_max,
    format: (row) => row.eps_guidance_max || "-",
    width: "12rem",
    right: true,
    sortable: true,
  },
  {
    name: "Importance",
    selector: (row) => row.importance,
    sortable: true,
    width: "8rem",
    right: true,
  },
];
