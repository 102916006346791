import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getCookie } from "../Utils";
import CSRFToken from "./CSRFToken";
import Toast from "./Toast";

const defaultTheme = createTheme();
const csrftoken = getCookie("csrftoken");

export default function ForgotPassword() {
  console.log("Forgot password!");
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      username_or_email: email,
      csrfmiddlewaretoken: csrftoken,
    };

    try {
      const response = await fetch("/passwordreset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();

      if (response.status === 200) {
        // Authentication successful, you can redirect the user to a new page
        setToastMessage(responseData.message);
        setOpenToast(true);
        setTimeout(() => navigate("/"), 1000);
      } else {
        setToastMessage(responseData.message);
        setErrorText(responseData.message);
        setOpenToast(true);
      }
    } catch (error) {
      // Handle network errors
      setToastMessage("Error sending the password link!");
      setOpenToast(true);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(val) => setEmail(val.target.value)}
            />
            <CSRFToken />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Send Verification Link
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography variant="caption" color="primary">
                  <Link to="/">Sign in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Toast
        open={openToast}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
}
