import { Box, Typography } from "@mui/material";
import StockDataTable from "../StockTable";

const StockStatistics = ({ ticker }) => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box mt={2} ml={2} sx={{ display: "flex", width: "100%" }}>
          <Typography fontSize="20px" fontWeight="Bold">
            {ticker.value} ({ticker.name})
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <StockDataTable ticker={ticker.value}></StockDataTable>
        </Box>
      </Box>
    </>
  );
};

export default StockStatistics;
