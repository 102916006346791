import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import { makeStyles } from "@mui/styles";
import CollapsibleRows from "./CollapsibleRows";

const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    fontWeight: "bold",
  },
}));

const TableComponent = ({ header, data }) => {
  const classes = useStyles();
  return (
    <Box>
      <TableContainer sx={{ display: "flex" }}>
        <Box m={4} sx={{ flex: 1, boxShadow: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    padding: "8px 16px 8px 16px",
                  }}
                  scope="row"
                >
                  {header}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data).map(([key, value]) =>
                typeof value === "object" && value !== null ? (
                  <CollapsibleRows header={key} data={value} />
                ) : (
                  <TableRow hover key={key}>
                    <TableCell
                      component="td"
                      scope="row"
                      sx={{ padding: "8px 16px 8px 16px" }}
                    >
                      {key}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        padding: "8px 16px 8px 16px",
                      }}
                    >
                      {value ? value : "NA"}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default TableComponent;
