import { TableRow, TableCell, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";

const CollapsibleRows = ({ header, data }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="td"
          scope="row"
          sx={{ fontWeight: "bold", padding: "8px 16px 8px 16px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {header}
        </TableCell>
      </TableRow>
      {open &&
        Object.entries(data).map(([key, value]) => (
          <TableRow hover>
            <TableCell component="td" sx={{ padding: "8px 16px 8px 15%" }}>
              {key}
            </TableCell>
            <TableCell
              component="td"
              align="right"
              sx={{ fontWeight: "bold", padding: "8px 16px 8px 16px" }}
            >
              {value ? value : "NA"}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default CollapsibleRows;
