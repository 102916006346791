import { useMediaQuery, useTheme, Divider, Tab, Tabs } from "@mui/material";

const StockDataSelectionTab = ({ selection, setselection }) => {
  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Tabs
      sx={{ marginTop: "2%" }}
      centered
      indicatorColor="secondary"
      textColor="secondary"
      value={selection}
      onChange={(event, value) => setselection(value)}
      variant={isMDScreen ? "fullWidth" : "scrollable"}
      scrollButtons={!isMDScreen}
      allowScrollButtonsMobile={!isMDScreen}
    >
      <Tab
        label="Statistics"
        value="statistics"
        sx={{ fontSize: isMDScreen ? "1.2rem" : "0.9rem" }}
      />
      <Divider orientation="vertical" flexItem />
      <Tab
        label="Fundamentals"
        value="fundamentals"
        sx={{ fontSize: isMDScreen ? "1.2rem" : "0.9rem" }}
      />
      <Divider orientation="vertical" flexItem />
      <Tab
        label="News"
        value="news"
        sx={{ fontSize: isMDScreen ? "1.2rem" : "0.9rem" }}
      />
    </Tabs>
  );
};

export default StockDataSelectionTab;
