import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { getCookie } from "../Utils";
import CSRFToken from "./CSRFToken";
import { useNavigate } from "react-router-dom";
import Toast from "./Toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useAuth } from "../Context/AuthContext";

const csrftoken = getCookie("csrftoken");

export default function SignIn() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [errorText, setErrorText] = useState("");
  const [formData, setformData] = useState({
    username_or_email: "",
    password: "",
  });

  const { setIsAuthenticated } = useAuth();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeChecked = () => {
    setformData((prev) => {
      return { ...prev, checked: !prev.checked };
    });
  };

  const handleChangeEmail = (event) => {
    setformData((prev) => {
      return { ...prev, username_or_email: event.target.value };
    });
  };

  const handleChangePassword = (event) => {
    setformData((prev) => {
      return { ...prev, password: event.target.value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Send a POST request to your authentication API endpoint

    try {
      const response = await fetch("/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        navigate("/news");
      } else {
        // Authentication failed, you can handle the error (e.g., show an error message)
        console.error("Authentication failed");
        const responseData = await response.json();
        setToastMessage(responseData.message); // Assuming responseData contains a 'message' property
        setErrorText(responseData.message);
        setOpenToast(true);
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username_or_email"
              label="Email Address or Username"
              name="username_or_email"
              autoComplete="email"
              autoFocus
              onChange={handleChangeEmail}
            />
            <CSRFToken />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={handleChangePassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // error={Boolean(errorText)}
              // helperText={errorText}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              checked={formData.checked}
              onChange={handleChangeChecked}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography variant="caption" color="primary">
                  <Link to="/passwordreset">Forgot password?</Link>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption" color="primary">
                  <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Toast
        open={openToast}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
}
