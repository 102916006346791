import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);

  const setAdminStatus = (isAdmin) => {
    setIsAdmin(isAdmin);
  };

  const fetchStatus = () => {
    fetch("/user", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setAdminStatus(data.is_admin);
        setIsAuthenticated(data.is_authenticated);
        setLoggedOut(!data.is_authenticated);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchStatus();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAdmin,
        setAdminStatus,
        isAuthenticated,
        setIsAuthenticated,
        loggedOut,
        setLoggedOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
