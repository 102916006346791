import Grid from "@mui/system/Unstable_Grid/Grid";
import { useContext, useState } from "react";
import { useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataContext } from "../Context/DataContext";

createTheme(
  "solarized",
  {
    text: {
      primary: "#ffff00",
      secondary: "#ffff00",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const convertToLocalTime = (dateString) => {
  const dateObject = new Date(dateString);
  return dateObject.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const getGridBreakpoints = { xs: 10, md: 10, lg: 10 };

const customStyles = {
  subHeader: {
    style: {
      paddingLeft: 10,
      marginTop: 10,
      marginBottom: 7,
      borderRadius: 6,
    },
  },
  cells: {
    style: {
      padding: 1,
      fontSize: "0.9rem",
    },
  },
  headCells: {
    style: { padding: 1, fontSize: "0.9rem" },
  },
  rows: {
    style: {
      paddingLeft: 10,
    },
  },
  head: {
    style: {
      paddingLeft: 10,
    },
  },
};

const EconomicDataApp = () => {
  const { eventsData } = useContext(DataContext);
  const [filterImportance, setFilterImportance] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [showPastEvents, setshowPastEvents] = useState(false);

  const handleFilterChange = (e) => {
    setFilterImportance(e.target.value);
  };

  const columns = [
    {
      name: "Time",
      selector: (row) => row.date,
      sortable: true,
      format: (row) => convertToLocalTime(row.date),
      width: "6rem",
      left: true,
      reorder: true,
    },
    {
      name: "Event",
      selector: (row) => row.event,
      sortable: true,
      width: "20rem",
      left: true,
      reorder: true,
    },
    {
      name: "Actual",
      selector: (row) => row.actual,
      sortable: true,
      width: "7rem",
      center: true,
      reorder: true,
    },
    {
      name: "Previous",
      selector: (row) => row.previous,
      sortable: true,
      width: "7rem",
      center: true,
      reorder: true,
    },
    {
      name: "Forecast",
      selector: (row) => row.forecast,
      sortable: true,
      width: "7rem",
      center: true,
      reorder: true,
    },
    {
      name: "Consensus",
      selector: (row) => row.teforecast,
      sortable: true,
      width: "8rem",
      center: true,
      reorder: true,
    },
    {
      name: "Importance",
      selector: (row) => row.importance,
      sortable: true,
      width: "8rem",
      center: true,
      reorder: true,
    },

    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
      width: "6rem",
      center: true,
      reorder: true,
    },
  ];

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);

    const timeFilteredEventsData = showPastEvents
      ? eventsData
      : Object.keys(eventsData)
          .filter((key) => new Date(key).getTime() >= today)
          .reduce((filteredObj, key) => {
            filteredObj[key] = eventsData[key];
            return filteredObj;
          }, {});

    const filteredData = filterImportance.length
      ? Object.keys(timeFilteredEventsData).reduce((result, key) => {
          result[key] = timeFilteredEventsData[key].filter((item) =>
            filterImportance.includes(item.importance)
          );
          return result;
        }, {})
      : timeFilteredEventsData;

    setfilteredData(filteredData);
  }, [filterImportance, eventsData, showPastEvents]);

  return (
    <>
      <Grid
        container
        id="edcontainer"
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
        >
          {Boolean(Object.entries(eventsData).length) ? (
            <Box sx={{ width: "100%", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  <Typography variant="h5">
                    US Economic Events Calendar
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                    }}
                  >
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showPastEvents}
                            onChange={() => setshowPastEvents((prev) => !prev)}
                          />
                        }
                        label="View historical events"
                      />
                    </FormControl>
                  </Box>
                  <FormControl>
                    <InputLabel id="importance-multiple-checkbox-label">
                      Filter on Importance
                    </InputLabel>
                    <Select
                      labelId="importance-multiple-checkbox-label"
                      id="importance-checkbox"
                      value={filterImportance}
                      style={{ width: "12rem" }}
                      onChange={(e) => handleFilterChange(e)}
                      renderValue={(selected) => selected.join(", ")}
                      input={<OutlinedInput label="Filter on importance" />}
                      multiple
                      sx={{
                        width: "15rem",
                      }}
                    >
                      {["1", "2", "3"].map((number) => (
                        <MenuItem value={number} key={number}>
                          <Checkbox
                            checked={filterImportance.includes(number)}
                          />
                          <ListItemText primary={number} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              {Boolean(Object.keys(filteredData).length) ? (
                Object.entries(filteredData).map(
                  ([key, value]) =>
                    Boolean(value.length) && (
                      <DataTable
                        columns={columns}
                        data={value}
                        sortIcon={<ArrowDownward />}
                        customStyles={customStyles}
                        theme="solarized"
                        subHeader={true}
                        responsive={true}
                        subHeaderComponent={
                          <Typography sx={{ fontWeight: "bold" }}>
                            {key}
                          </Typography>
                        }
                        subHeaderAlign="left"
                        // expandableRows
                        dense
                      />
                    )
                )
              ) : (
                <Box mt={6}>
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </Box>
          ) : (
            <Box mt={6}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EconomicDataApp;
