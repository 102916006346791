import Grid from "@mui/system/Unstable_Grid/Grid";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMediaQuery, useTheme } from "@mui/material";
import Toast from "../Utils/Toast";

const convertToLocalTime = (dateString) => {
  const dateObject = new Date(dateString);
  return dateObject.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const getGridBreakpoints = { xs: 10, md: 10, lg: 10 };

const customStyles = {
  subHeader: {
    style: {
      paddingLeft: 10,
      marginTop: 10,
      marginBottom: 7,
      borderRadius: 6,
    },
  },
  cells: {
    style: {
      padding: 1,
      fontSize: "0.9rem",
    },
  },
  headCells: {
    style: { padding: 1, fontSize: "0.9rem" },
  },
  rows: {
    style: {
      paddingLeft: 10,
    },
  },
  head: {
    style: {
      paddingLeft: 10,
    },
  },
};

createTheme(
  "solarized",
  {
    text: {
      primary: "#ffff00",
      secondary: "#ffff00",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const EventScheduler = () => {
  const [eventsData, seteventsData] = useState([]);
  const [filterImportance, setFilterImportance] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [showMessage, setshowMessage] = useState(false);
  const [message, setmessage] = useState("");
  const [requestsConsumed, setRequestsConsumed] = useState(-1);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const groupByDate = (apiResponse) => {
    const groupedByDate = {};

    apiResponse.forEach((response) => {
      const date = new Date(response.date).toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (!groupedByDate[date]) {
        groupedByDate[date] = [];
      }

      groupedByDate[date].push(response);
    });

    return groupedByDate;
  };

  const handleFilterChange = (e) => {
    setFilterImportance(e.target.value);
  };

  const handleRowSelection = (key, selectionDetails) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = { ...prevSelectedRows };
      updatedSelectedRows[key] = new Set(
        selectionDetails.selectedRows.map((row) => row.date)
      );
      return updatedSelectedRows;
    });
  };

  const handleRefreshNow = () => {
    const requestOptions = {
      method: "POST",
    };

    fetch("/api/economicevents", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setmessage(data?.message);
        setshowMessage(true);
        fetchEvents();
      })
      .catch((err) => {
        setmessage("Error saving schedule.");
        setshowMessage(true);
        console.log(err);
      });
  };

  const handleSaveSchedule = () => {
    const combinedSet = [];

    for (const key in selectedRows) {
      const set = selectedRows[key];
      for (const item of set) {
        combinedSet.push(item);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type
        // You can include other headers if needed, like authentication headers
      },
      body: JSON.stringify(combinedSet), // Convert the data to JSON format
    };

    fetch("/api/eventscheduler", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setmessage(data?.message);
        setshowMessage(true);
      })
      .catch((err) => {
        setmessage("Error saving schedule.");
        setshowMessage(true);
        console.log(err);
      });
  };

  const fetchEvents = () => {
    fetch("/api/eventscheduler")
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      })
      .then((data) => {
        const groupedData = groupByDate(data.events);
        seteventsData(groupedData);
        setRequestsConsumed(data.requests_consumed);
      })

      .catch((err) => {
        setmessage("Error fetching data.");
        setshowMessage(true);
        console.log(err);
      });
  };

  const columns = [
    {
      name: "Time",
      selector: (row) => row.date,
      sortable: true,
      format: (row) => convertToLocalTime(row.date),
      width: "6rem",
      left: true,
      reorder: true,
    },
    {
      name: "Event",
      selector: (row) => row.event,
      sortable: true,
      width: "20rem",
      left: true,
      reorder: true,
    },
    {
      name: "Actual",
      selector: (row) => row.actual,
      sortable: true,
      width: "7rem",
      center: true,
      reorder: true,
    },
    {
      name: "Previous",
      selector: (row) => row.previous,
      sortable: true,
      width: "7rem",
      center: true,
      reorder: true,
    },
    {
      name: "Forecast",
      selector: (row) => row.forecast,
      sortable: true,
      width: "7rem",
      center: true,
      reorder: true,
    },
    {
      name: "Consensus",
      selector: (row) => row.teforecast,
      sortable: true,
      width: "8rem",
      center: true,
      reorder: true,
    },
    {
      name: "Importance",
      selector: (row) => row.importance,
      sortable: true,
      width: "8rem",
      center: true,
      reorder: true,
    },

    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
      width: "6rem",
      center: true,
      reorder: true,
    },
  ];

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    const filteredData = filterImportance.length
      ? Object.keys(eventsData).reduce((result, key) => {
          result[key] = eventsData[key].filter((item) =>
            filterImportance.includes(item.importance)
          );
          return result;
        }, {})
      : eventsData;

    setfilteredData(filteredData);
  }, [filterImportance, eventsData]);

  const defaultSelectedRows = useCallback((row) => {
    return row.is_scheduled;
  }, []);

  return (
    <>
      <Grid
        container
        id="edcontainer"
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
        >
          <Box sx={{ width: "100%", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  width: "30%",
                }}
              >
                <Typography variant="h5">
                  US Economic Events Calendar
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", width: isLargeScreen ? "50%" : "70%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      id="buttonbox"
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "80%",
                        height: "70%",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleSaveSchedule()}
                      >
                        Save Schedule
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleRefreshNow()}
                      >
                        Refresh Now
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "80%",
                        marginTop: 1,
                      }}
                    >
                      <Typography>
                        {requestsConsumed > -1
                          ? `Requests consumed this month: ${requestsConsumed}`
                          : ""}
                      </Typography>
                      <div></div>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", widht: "30%" }}>
                    <FormControl>
                      <InputLabel id="importance-multiple-checkbox-label">
                        Filter on Importance
                      </InputLabel>
                      <Select
                        labelId="importance-multiple-checkbox-label"
                        id="importance-checkbox"
                        value={filterImportance}
                        style={{ width: "12rem" }}
                        onChange={(e) => handleFilterChange(e)}
                        renderValue={(selected) => selected.join(", ")}
                        input={<OutlinedInput label="Filter on importance" />}
                        multiple
                        sx={{
                          width: "15rem",
                        }}
                      >
                        {["1", "2", "3"].map((number) => (
                          <MenuItem value={number} key={number}>
                            <Checkbox
                              checked={filterImportance.includes(number)}
                            />
                            <ListItemText primary={number} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>

            {Boolean(
              Object.keys(filteredData).length &&
                Object.keys(filteredData).length
            ) ? (
              Object.entries(filteredData).map(
                ([key, value]) =>
                  Boolean(value.length) && (
                    <DataTable
                      key={key}
                      columns={columns}
                      data={value}
                      sortIcon={<ArrowDownward />}
                      customStyles={customStyles}
                      subHeader={true}
                      subHeaderComponent={
                        <Typography sx={{ fontWeight: "bold" }}>
                          {key}
                        </Typography>
                      }
                      theme="solarized"
                      responsive={true}
                      dense
                      selectableRows
                      selectableRowSelected={defaultSelectedRows}
                      subHeaderAlign="left"
                      onSelectedRowsChange={(rowDetails) =>
                        handleRowSelection(key, rowDetails)
                      }
                    />
                  )
              )
            ) : (
              <Box mt={6}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Toast
        open={showMessage}
        message={message}
        onClose={() => setshowMessage((prev) => !prev)}
      />
    </>
  );
};

export default EventScheduler;
