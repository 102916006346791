import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import NewsContainer from "./NewContainer";
import NewsHeadBar from "./NewsHeadBar";
import { Box, LinearProgress, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const getGridBreakpoints = { xs: 10, md: 10, lg: 10 };

export default function NewsApp() {
  const [selectedDateRange, setSelectedDateRange] = useState("pastday");
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    fetchNewsData();
  }, [selectedDateRange]);

  const handleDateRangeChange = async (event) => {
    setSelectedDateRange(event.target.value);
  };

  const handleRefreshClick = async () => {
    setIsloading((val) => !val);
    fetchNewsData();
  };

  const fetchNewsData = () => {
    fetch(`/api/news?daterange=${selectedDateRange}`)
      .then((response) => response.json())
      .then((data) => {
        setNewsData(data);
        setIsloading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsloading(false);
      });
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={getGridBreakpoints.xs}
        md={getGridBreakpoints.md}
        lg={getGridBreakpoints.lg}
        sx={{ width: "100%" }}
      >
        <NewsHeadBar
          isLoading={isLoading}
          selectedDateRange={selectedDateRange}
          handleDateRangeChange={handleDateRangeChange}
          handleRefreshClick={handleRefreshClick}
        />
      </Grid>

      {isLoading ? (
        <Box
          mt={10}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LinearProgress
            variant="indeterminate"
            style={{ width: "50%" }}
            color="secondary"
          />
        </Box>
      ) : (
        <Grid
          item
          xs={getGridBreakpoints.xs}
          md={getGridBreakpoints.md}
          lg={getGridBreakpoints.lg}
          sx={{ width: "100%" }}
        >
          {Boolean(newsData.length) ? (
            <NewsContainer newsData={newsData} />
          ) : (
            <Box
              mt={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <SentimentDissatisfiedIcon
                  color="secondary"
                  sx={{ fontSize: "5rem" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="secondary">
                  Sorry, no results found for selected date range!
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
}
