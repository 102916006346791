import "./App.css";
import SignUp from "./Components/Utils/SignUp";
import SignIn from "./Components/Utils/SignIn";
import ForgotPassword from "./Components/Utils/ForgotPassword";
import NewsApp from "./Components/News/NewsApp";
import PasswordConfirm from "./Components/Utils/PasswordConfirm";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./Components/Containers/MainApp";
import EconomicDataApp from "./Components/Economicdata/EDPlaceHolder";
import FinancialInsightsApp from "./Components/Insights/FinancialInsightsApp";
import StockDataApp from "./Components/StockData/StockDataApp";
import { AuthProvider, useAuth } from "./Components/Context/AuthContext";
import EventScheduler from "./Components/Economicdata/EvenScheduler";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { EarningsCalendarApp } from "./Components/EarningsData/EarningsCalendarApp";
import { DataContextProvider } from "./Components/Context/DataContext";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#071337",
    },
    secondary: {
      main: "#ffff00",
    },
    third: {
      main: "#F8F6F0",
    },
    text: {
      primary: "#ffff00", // Font color for primary text
      secondary: "#ffff00", // Font color for secondary text
      third: "#F8F6F0",
    },
    background: {
      default: "#000",
      paper: "#121212",
    },
  },
  overrides: {},
});

function App() {
  const { loggedOut } = useAuth();

  return (
    <>
      <BrowserRouter>
        <DataContextProvider>
          <ThemeProvider theme={darkTheme}>
            <Routes>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/" element={<SignIn />} />
              <Route path="/passwordreset" element={<ForgotPassword />} />
              <Route
                path="/reset/:uidb64/:token/"
                element={<PasswordConfirm />}
              />
              {!loggedOut ? (
                <>
                  <Route path="/news" element={<MainApp />}>
                    <Route path="" element={<NewsApp />} />
                  </Route>
                  <Route path="/insights/*" element={<MainApp />}>
                    <Route path="" element={<FinancialInsightsApp />} />
                  </Route>
                  <Route path="/stockdata/*" element={<MainApp />}>
                    <Route path="" element={<StockDataApp />} />
                  </Route>
                  <Route path="/economicdata/*" element={<MainApp />}>
                    <Route path="" element={<EconomicDataApp />} />
                  </Route>
                  <Route path="/earningsdata/*" element={<MainApp />}>
                    <Route path="" element={<EarningsCalendarApp />} />
                  </Route>
                  <Route path="/eventscheduler" element={<MainApp />}>
                    <Route path="" element={<EventScheduler />} />
                  </Route>
                </>
              ) : (
                <Route path="/*" element={<SignIn />} />
              )}
            </Routes>
          </ThemeProvider>
        </DataContextProvider>
      </BrowserRouter>
    </>
  );
}

const AuthApp = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AuthApp;
