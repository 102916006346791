import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Divider, CardActionArea } from "@mui/material";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function NewsArticle({
  title,
  imageUrl,
  newsUrl,
  summary,
  date,
  source,
}) {
  const dateObject = new Date(date);
  const isLargeScreen = useMediaQuery("(min-width: 1280px)");
  const handleCardClick = () => {
    window.open(newsUrl, "_blank");
  };

  return (
    <CardActionArea
      onClick={handleCardClick}
      style={{
        cursor: "pointer",
      }}
    >
      <Card
        sx={{
          boxShadow: 5,
          display: "flex",
          "&:hover": {
            boxShadow: 15,
          },
        }}
      >
        {isLargeScreen && (
          <CardMedia
            component="img"
            alt="News Image"
            image={imageUrl}
            sx={{
              // Set the image to take up 25% of the card's height
              padding: "1rem",
              width: "25%",
            }}
          />
        )}

        <CardContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginBottom: "2px",
            }}
          >
            {title}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography variant="caption" color="text.primary">
              {source}
              {" | "}
              {dateObject.toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              {" | "}
              {dateObject.toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Box>
          <Divider
            sx={{
              marginTop: "8px",
              marginBottom: "2px",
            }}
          />
          <Box style={{ paddingBottom: "1rem", marginTop: "0.5rem" }}>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {summary}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}
