import { Drawer, List, useTheme, useMediaQuery } from "@mui/material";

import PsychologyIcon from "@mui/icons-material/Psychology";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import InsightsIcon from "@mui/icons-material/Insights";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SideBarItem from "./SideBarItem";
import { useAuth } from "../Context/AuthContext";

const iconsMap = {
  News: <NewspaperIcon />,
  Summarizer: <PsychologyIcon />,
  "Economic Data Explorer": <QueryStatsIcon />,
  "Stock Data Explorer": <InsightsIcon />,
  "Earnings Calendar": <AttachMoneyIcon />,
};

const routeMap = {
  News: "/news",
  Summarizer: "/insights",
  "Economic Data Explorer": "/economicdata",
  "Stock Data Explorer": "/stockdata",
  "Earnings Calendar": "/earningsdata",
};

const SideBar = () => {
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const drawerWidth = isLgScreen ? "15rem" : "5rem";
  const { isAdmin } = useAuth();

  const listSx = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "20rem",
    alignContent: "center",
    marginTop: "4rem",
  };

  const drawerSx = {
    width: drawerWidth,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  };

  return (
    <Drawer variant="permanent" sx={drawerSx}>
      <List sx={listSx}>
        {Object.keys(iconsMap).map((key) => (
          <SideBarItem
            key={key}
            tab={key}
            componentIcon={iconsMap[key]}
            componentRoute={routeMap[key]}
            showText={isLgScreen}
          />
        ))}
        {isAdmin && (
          <SideBarItem
            key="Event Scheduler"
            tab="Event Scheduler"
            componentIcon={<ScheduleIcon />}
            componentRoute="/eventscheduler"
            showText={isLgScreen}
          ></SideBarItem>
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
