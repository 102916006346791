import Grid from "@mui/system/Unstable_Grid/Grid";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import { SentimentVeryDissatisfied } from "@mui/icons-material";
import NewsContainer from "../../News/NewContainer";

const StockNews = ({ ticker, setIsloading, isLoading }) => {
  const [newsData, setNewsData] = useState([]);
  const [controller, setController] = useState(new AbortController());

  const fetchTickerNews = (fetchTicker) => {
    controller.abort();

    const newController = new AbortController();
    setController(newController);

    const data = fetch(`/api/stocknews/${fetchTicker}/`, {
      signal: newController.signal,
    });
    data
      .then((data) => data.json())
      .then((val) => setNewsData(val))
      .then(() => setIsloading(false))
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Request aborted by user.");
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    fetchTickerNews(ticker);
    return () => {
      controller.abort();
    };
  }, [ticker]);

  return (
    <Grid
      container
      spacing={3} // Add the spacing prop here
      style={{
        display: "flex",
        borderColor: "black",
        marginTop: "2%",
        width: "100%",
      }}
      justifyContent="center"
      alignItems="center"
    >
      {isLoading ? (
        <Grid item sx={{ justifyContent: "center" }}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : Boolean(newsData.length) ? (
        <Grid item>
          <NewsContainer newsData={newsData} />
        </Grid>
      ) : (
        <>
          <Grid item>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Typography variant="h5" color="primary">
                Apologies, we couldn't find any articles from the last 7 days.{" "}
              </Typography>
            </Box>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <SentimentVeryDissatisfied
                color="primary"
                sx={{ fontSize: "60px" }}
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default StockNews;
