import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";

const SelectionTab = ({ selectedTab, handleTabChange }) => {
  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      indicatorColor="secondary"
      textColor="secondary"
      variant={isMDScreen ? "fullWidth" : "scrollable"}
      scrollButtons={!isMDScreen}
      allowScrollButtonsMobile={!isMDScreen}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: 2,
      }}
    >
      <Tab label="Upload" value="upload" sx={{ fontSize: "1.2rem" }} />
      <Tab label="URL" value="url" sx={{ fontSize: "1.2rem" }} />
      <Tab label="Text" value="text" sx={{ fontSize: "1.2rem" }} />
    </Tabs>
  );
};

export default SelectionTab;
