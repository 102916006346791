import { Box } from "@mui/material";

const MainContainer = ({ childComponent }) => {
  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "80%",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Box sx={{ width: "100%", height: "90%", padding: 1 }}>
        {childComponent}
      </Box>
    </Box>
  );
};

export default MainContainer;
