import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Box, fontSize } from "@mui/system";

const SummaryCheckbox = ({ checked, handleEmailSummaryCheckboxChange }) => {
  return (
    <Box ml={4}>
      <Checkbox onChange={handleEmailSummaryCheckboxChange} value={checked} />
    </Box>
  );
};

const EmailSummaryCheckBox = (props) => {
  return (
    <FormControl sx={{ justifyContent: "end" }}>
      <FormControlLabel
        control={<SummaryCheckbox {...props} />}
        label="Email Summary"
      ></FormControlLabel>
    </FormControl>
  );
};

export default EmailSummaryCheckBox;
