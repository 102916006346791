import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  TextareaAutosize,
  Dialog,
  LinearProgress,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import WordCount from "./WordCount";
import SelectionTab from "./SelectionTab";
import { DropzoneArea } from "material-ui-dropzone";
import { getCookie } from "../Utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import EmailSummaryCheckBox from "./EmailSummaryCheckbox";

const csrftoken = getCookie("csrftoken");

export default function DocumentSummarizer() {
  const [isSummaryDialogOpen, setIsSummaryDialogOpen] = useState(false);
  const [summary, setSummary] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedTab, setSelectedTab] = useState("upload");
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(100);
  const [formData, setformData] = useState({});
  const [emailSummary, setemailSummary] = useState(false);
  const [showError, setshowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const theme = useTheme();
  const isSMScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDownloadClick = async () => {
    const formData = new FormData();
    formData.append("is_download", true);
    formData.append("summary_text", summary);

    try {
      const response = await fetch("/api/insights", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrftoken,
        },
        body: formData,
      });
      const responseData = await response.blob();

      if (response.status === 200) {
        const blobUrl = URL.createObjectURL(responseData);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "Summary.pdf";
        downloadLink.dispatchEvent(new MouseEvent("click"));
      } else {
        console.error("Server error:", responseData);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleEmailSummaryCheckboxChange = (event, changedVal) => {
    setemailSummary(changedVal);
    setformData((prev) => {
      return {
        ...prev,
        email_summary: changedVal,
      };
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setErrorMessage("");
    setshowError(false);

    if (newValue === "upload") {
      setformData({
        document: file,
        word_count: wordCount,
        email_summary: emailSummary,
      });
    }
    if (newValue === "url") {
      setformData({
        urlbox: url,
        word_count: wordCount,
        email_summary: emailSummary,
      });
    }
    if (newValue === "text") {
      setformData({
        textbox: text,
        word_count: wordCount,
        email_summary: emailSummary,
      });
    }
  };

  const handleFileChange = (files) => {
    setformData({
      document: files[0],
      word_count: wordCount,
      email_summary: emailSummary,
    });
    setFile(files[0]);
  };

  const handleUrlChange = (event) => {
    setformData({
      word_count: wordCount,
      urlbox: event.target.value,
      email_summary: emailSummary,
    });
    setUrl(event.target.value);
  };

  const handleTextChange = (event) => {
    setformData({
      textbox: event.target.value,
      word_count: wordCount,
      email_summary: emailSummary,
    });
    setText(event.target.value);
  };

  const handleCloseSummaryDialog = () => {
    setIsSummaryDialogOpen(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(summary);
  };

  const handleWordCountChange = (event) => {
    var changedVal = event.target.value
      ? parseInt(event.target.value)
      : event.target.value;

    changedVal = changedVal > 1000 ? 1000 : changedVal;
    changedVal = changedVal <= 0 ? "" : changedVal;
    setWordCount(changedVal);
    setformData((prev) => {
      return {
        ...prev,
        word_count: changedVal,
      };
    });
  };

  const handleSummarizeClick = async () => {
    setIsProcessing(true);

    // Send the selected tab, file, url, and text to the backend for processing

    const bodyFormData = new FormData();
    Object.keys(formData).map((key) => bodyFormData.append(key, formData[key]));

    try {
      const response = await fetch("/api/insights", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrftoken,
        },
        body: bodyFormData,
      });

      const responseData = await response.json();

      if (response.status === 200) {
        // Server response received successfully
        const { summary_text } = responseData;
        setSummary(summary_text);
        setIsSummaryDialogOpen(true);
        setIsProcessing(false);
      } else {
        const { error } = responseData;
        setSelectedTab("text");
        setErrorMessage(
          `${error} Paste content from the source in text box to summarize!`
        );
        setshowError(true);
        console.error("Server error:", responseData);
        setIsProcessing(false);
      }
    } catch (error) {
      setIsProcessing(false);
      console.error("Network error:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "70%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            height: "70%",
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {isSMScreen && (
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2">
                This is our summary tool using AI. It will summarize anything
                quickly, specifically designed for finance.
              </Typography>
              <Typography variant="body2">
                Feel free to paste financial news URLs, files, earnings reports
                and more for fast digestion of finance data.
              </Typography>
            </Box>
          )}

          <SelectionTab
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />
          <Box
            sx={{
              width: "100%",
              height: "18rem",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            {selectedTab === "upload" && (
              <DropzoneArea
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                dropzoneProps={{ noDragEventsBubbling: false }}
                previewText="Uploaded File:"
                previewChipProps={{ color: "primary" }}
                acceptedFiles={[".pdf", ".doc", ".docx", ".txt"]}
                filesLimit={1}
                onChange={(files) => handleFileChange(files)}
                showAlerts={false}
              />
            )}
            {selectedTab === "url" && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  border: "2px dashed #0000001f",
                }}
              >
                <Box m={2}>
                  <TextField
                    label="Enter URL"
                    variant="outlined"
                    fullWidth
                    value={url}
                    onChange={handleUrlChange}
                  />
                </Box>
              </Box>
            )}
            {selectedTab === "text" && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  border: "2px dashed #0000001f",
                }}
              >
                <Box
                  m={2}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <TextareaAutosize
                    placeholder="Paste your text here..."
                    value={text}
                    onChange={handleTextChange}
                    minHeight={"100%"}
                    sx={{
                      minHeight: "100%",
                      height: "100%",
                      resize: "none",
                    }}
                    style={{ color: "#ffff00", "background-color": "#121212" }}
                    minRows={15}
                    maxRows={15}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {showError && <Typography color="error">{errorMessage}</Typography>}
          <Box sx={{ display: "flex" }}>
            <WordCount
              wordCount={wordCount}
              handleWordCountChange={handleWordCountChange}
            />
            <EmailSummaryCheckBox
              checked={emailSummary}
              handleEmailSummaryCheckboxChange={
                handleEmailSummaryCheckboxChange
              }
            />
          </Box>
          {isProcessing && (
            <LinearProgress sx={{ marginTop: "1%" }} color="secondary" />
          )}
          <Box mt={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSummarizeClick}
              disabled={isProcessing ? true : false}
              sx={{ color: "#ffff00" }}
            >
              Summarize
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isSummaryDialogOpen}
        onClose={handleCloseSummaryDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <Typography variant="h5">Summary</Typography>
          <Tooltip title="Copy Content">
            <IconButton sx={{ cursor: "pointer" }} onClick={handleCopyClick}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <Divider variant="middle" />
        <Box p={2}>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: "pre-line" }}>
              {summary}
            </DialogContentText>
          </DialogContent>
          <Box mt={2}>
            <DialogActions>
              <Button variant="contained" onClick={handleDownloadClick}>
                Download as PDF <DownloadIcon />
              </Button>
              <Button variant="contained" onClick={handleCloseSummaryDialog}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
