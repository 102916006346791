import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const NewsHeadBar = ({
  selectedDateRange,
  handleDateRangeChange,
  isLoading,
  handleRefreshClick,
}) => {
  const theme = useTheme();
  const isSMScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const getNewsHeaderString = () => {
    var val;

    switch (selectedDateRange) {
      case "pasthour":
        val = "past hour's";
        break;
      case "pastday":
        val = "today's";
        break;
      case "pastweek":
        val = "past week's";
        break;
      case "pastmonth":
        val = "past month's";
        break;
      default:
        break;
    }
    const string = `These are ${val} biggest news stories in markets, summarized so you can ingest it quickly.`;
    return string;
  };

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <FormControl size="small">
            <InputLabel id="select-required-label">Date Range</InputLabel>
            <Select
              labelId="select-required-label"
              value={selectedDateRange}
              label="Date Range"
              placeholder="Date Range"
              onChange={handleDateRangeChange}
              sx={{
                width: "10rem",
                height: "100%",
              }}
            >
              <MenuItem value="pasthour">Past Hour</MenuItem>
              <MenuItem value="pastday">Past Day</MenuItem>
              <MenuItem value="pastweek">Past Week</MenuItem>
              <MenuItem value="pastmonth">Past Month</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            {isLoading ? (
              <CircularProgress sx={{ fontSize: "2.75rem" }} />
            ) : (
              <SyncIcon
                sx={{ fontSize: "2.75rem" }}
                onClick={handleRefreshClick}
                color="secondary"
              />
            )}
          </Box>
        </Box>
        {isSMScreen && (
          <Box mt={2}>
            <Typography variant="body2">{getNewsHeaderString()}</Typography>
            <Typography variant="body2"></Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default NewsHeadBar;
