import { createContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventsData, seteventsData] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchEvents();
      fetchEarningsData();
    }
  }, [isAuthenticated]);

  const groupByDate = (apiResponse) => {
    const groupedByDate = {};

    apiResponse.forEach((response) => {
      const date = new Date(response.date).toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (!groupedByDate[date]) {
        groupedByDate[date] = [];
      }

      groupedByDate[date].push(response);
    });

    return groupedByDate;
  };

  const fetchEvents = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    fetch(`api/economicevents?tz=${timeZone}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      })
      .then((data) => {
        const groupedData = groupByDate(data?.events);
        seteventsData(groupedData);
      })

      .catch((err) => console.log(err));
  };

  const fetchEarningsData = (date) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = date
      ? `api/earnings?tz=${timeZone}&date=${date}`
      : `api/earnings?tz=${timeZone}`;
    setLoading(true);
    fetch(url, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setData(data.earnings);
        const dateSet = data.dates;
        var selectedDate;
        if (date) {
          selectedDate = dateSet.length ? dateSet[1] : null;
        } else {
          const today = new Date();
          const formattedDate = today.toISOString().split("T")[0];

          selectedDate = dateSet.includes(formattedDate)
            ? formattedDate
            : dateSet[0];
        }

        setDates(dateSet);
        setDate(selectedDate);
        setTableData(
          data.earnings.filter((item) => item.date === selectedDate)
        );
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <DataContext.Provider
      value={{
        data,
        date,
        dates,
        setDate,
        tableData,
        setTableData,
        fetchEarningsData,
        loading,
        setLoading,
        eventsData,
        seteventsData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
