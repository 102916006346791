import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import MainContainer from "./MainContainer";
import { useAuth } from "../Context/AuthContext";
import { useEffect } from "react";

const MainApp = () => {
  return (
    <Box>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box sx={{ zIndex: 1, position: "fixed", width: "100%" }}>
          <TopBar />
        </Box>

        <Box
          sx={{
            display: "flex",
            zIndex: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <SideBar />
          <MainContainer childComponent={<Outlet />} />
        </Box>
      </Box>
    </Box>
  );
};

export default MainApp;
