import { Grid, Box } from "@mui/material";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

const StockDataTable = ({ ticker }) => {
  const [statisticsData, setStaticticsData] = useState({});
  const [isLoading, setisLoading] = useState(false);

  const fetchStockStatsData = async (ticker) => {
    try {
      const data = fetch(`/api/stockstatistics/${ticker}/`);
      data
        .then((res) => {
          const jsonData = res.ok ? res.json() : {};
          return jsonData;
        })
        .then((data) => {
          setStaticticsData(data);
          setisLoading(false);
        });
    } catch {
      console.log("Error");
      setStaticticsData({});
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchStockStatsData(ticker);
    setisLoading(true);
  }, [ticker]);
  return isLoading ? (
    <Box
      mt={4}
      sx={{ display: "flex", width: "60%", justifyContent: "center" }}
    >
      <CircularProgress sx={{ width: "60%" }} color="secondary" />
    </Box>
  ) : (
    <Grid container sx={{ justifyContent: "space-around" }}>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ flex: 1 }}>
        <Grid container>
          {Object.entries(statisticsData)
            .slice(0, 3)
            .map(([key, value]) => (
              <Grid item sx={{ width: "100%" }}>
                <TableComponent header={key} data={value} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ flex: 1 }}>
        <Grid container>
          {Object.entries(statisticsData)
            .slice(3, 5)
            .map(([key, value]) => (
              <Grid item sx={{ width: "100%" }}>
                <TableComponent header={key} data={value} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StockDataTable;
