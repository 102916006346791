import React from "react";
import NewsArticle from "./NewsArticle";
import { Grid, Pagination } from "@mui/material";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";

const NewsContainer = ({ newsData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(newsData.length / itemsPerPage);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedNews = newsData.slice(startIndex, endIndex);

  useEffect(() => setCurrentPage(1), [newsData]);

  return (
    <Box mt={2}>
      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          borderColor: "black",
          justifyContent: "center",
        }}
      >
        {displayedNews.map((news, index) => (
          <React.Fragment key={index}>
            <Grid item>
              <NewsArticle {...news} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <div style={{ textAlign: "center", margin: "1rem" }}>
        <div style={{ display: "inline-block" }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
          />
        </div>
      </div>
    </Box>
  );
};

export default NewsContainer;
