import { Box, Typography } from "@mui/material";
import FundamentalsTable from "./FundamentalsTable";
import { useEffect, useState } from "react";

const IncomeStatement = ({ ticker, periodSelection }) => {
  const [incomestatement, setincomestatement] = useState({});

  const fetchTickerIncomeStatement = (ticker, period) => {
    try {
      const data = fetch(`/api/incomestatement/${ticker}/${period}`);

      data
        .then((resp) => {
          const responseData = resp.ok ? resp.json() : {};
          return responseData;
        })
        .then((data) => setincomestatement(data));
    } catch {
      console.log("Error");
      setincomestatement({});
    }
  };

  useEffect(() => {
    fetchTickerIncomeStatement(ticker.value, periodSelection);
  }, [ticker.value, periodSelection]);

  return (
    <>
      <Box mt={3}>
        <Typography fontSize="20px" fontWeight="Bold">
          {ticker.value} ({ticker.name})
        </Typography>
      </Box>
      <FundamentalsTable header="Income Statement" data={incomestatement} />
    </>
  );
};

export default IncomeStatement;
