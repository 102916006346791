import { useState } from "react";
import FundamentalsSelectionTab from "./FundamentalsSelectionTab";
import BalanceSheet from "./BalanceSheet";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Fade } from "@material-ui/core";
import IncomeStatement from "./IncomeStatement";
import CashFlow from "./CashFlow";

const StockFundamentals = ({ ticker }) => {
  const [selection, setselection] = useState("balancesheet");
  const [periodSelection, setperiodSelection] = useState("annual");

  const handleFundamentalsSelectionChange = (event, newVal) => {
    event.preventDefault();
    setselection(newVal);
  };

  const handleFundamentalsPeriodChange = (event) => {
    event.preventDefault();
    setperiodSelection(event.target.value);
  };

  return (
    <>
      <Grid container sx={{ justifyContent: "center", width: "100%" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ justifyContent: "center", width: "80%" }}
        >
          <FundamentalsSelectionTab
            selection={selection}
            handleFundamentalsSelectionChange={
              handleFundamentalsSelectionChange
            }
            periodSelection={periodSelection}
            handleFundamentalsPeriodChange={handleFundamentalsPeriodChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ justifyContent: "center", width: "80%" }}
        >
          {selection === "incomestatement" && (
            <Fade in={selection === "incomestatement"}>
              <div>
                <IncomeStatement
                  periodSelection={periodSelection}
                  ticker={ticker}
                />
              </div>
            </Fade>
          )}
          {selection === "balancesheet" && (
            <Fade in={selection === "balancesheet"}>
              <div>
                <BalanceSheet
                  periodSelection={periodSelection}
                  ticker={ticker}
                />
              </div>
            </Fade>
          )}
          {selection === "cashflow" && (
            <Fade in={selection === "cashflow"}>
              <div>
                <CashFlow periodSelection={periodSelection} ticker={ticker} />
              </div>
            </Fade>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default StockFundamentals;
